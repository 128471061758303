import React from 'react';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import LandingSearchForm from '../components/LandingSearchForm';


const landingGridItems = [
  {
    img: 'landingGrid1',
    heading: 'LOCAL FARMERS WIN',
    description: 'By working exclusively with local farmers to sell directly through till, farmers are able to earn significantly more and work less than selling through traditional means.'
  },
  {
    img: 'landingGrid2',
    heading: 'LOCAL FAMILIES WIN',
    description: 'As a till customer, you get year-round access to the freshest, most nutritious foods without sacrificing convenience to get them, all while supporting local farms.'
  },
  {
    img: 'landingGrid3',
    heading: 'LOCAL FOOD DRIVES WIN',
    description: 'Over 900,000 Alabama residents struggle with hunger. When you shop with till, find peace in knowing we donate a portion of food ordered to local Birmingham food banks and missions.'
  }
]

const featureLogos = Array.from({length: 6}, (v, k) => `logo${k + 1}`);

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query {
    landingBanner1: file(relativePath: { eq: "landing-banner-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingBody1: file(relativePath: { eq: "landing-body-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingBody2: file(relativePath: { eq: "landing-body-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingBody3: file(relativePath: { eq: "landing-body-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingGrid1: file(relativePath: { eq: "landing-grid-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingGrid2: file(relativePath: { eq: "landing-grid-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingGrid3: file(relativePath: { eq: "landing-grid-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { eq: "feature-logo-1.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo2: file(relativePath: { eq: "feature-logo-2.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo3: file(relativePath: { eq: "feature-logo-3.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo4: file(relativePath: { eq: "feature-logo-4.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo5: file(relativePath: { eq: "feature-logo-5.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo6: file(relativePath: { eq: "feature-logo-6.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    phone: file(relativePath: { eq: "landing-phone.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landingBottom: file(relativePath: { eq: "landing-bottom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  return (
    <Layout homePage={true} pageTitle={`Birmingham's online farmers market`}>
      <div className='landing-jumbotron'>
        <div className='background-img'>
            <GatsbyImage fluid={data.landingBanner1.childImageSharp.fluid} style={{height: '100%'}} />
        </div>
        <div className='content'>
          <div>
            <h1>FARM TO DOOR</h1>
            <div className='width-container'>
              <p className='landing-p'>Enjoy the best Alabama farm-fresh foods delivered to your doorstep with till, Birmingham’s first online farmers market.</p>
              <LandingSearchForm />
            </div>
          </div>
        </div>
      </div>
      <div className='landing-body'>
        <section className='padding-section alt-section'>
          <div className='grid-container'>
            <div className='grid-item-2'>
              <div className='landing-body-section left'>
                <div>
                  <h1>THE FARMERS MARKET DELIVERED TO YOUR DOOR</h1>
                  <div className='position-relative'>
                    <div className='landing-hidden-img alt-img'>
                      <GatsbyImage fluid={data.landingBody1.childImageSharp.fluid} />
                    </div>
                    <div className='landing-hidden-phone'>
                      <GatsbyImage fluid={data.phone.childImageSharp.fluid} />
                    </div>
                  </div>
                  <p className='alt-landing-text landing-p'>Say goodbye to Saturday morning alarms. Use your computer or mobile device to peruse the same farms at the market from the comfort of anywhere. </p>
                  <Link to='/food/' className='btn primary-btn landing-btn'>SHOP NOW</Link>
                </div>
              </div>
            </div>
            <div className='grid-item-2'>
              <div className='position-relative alt-img-container'>
                <div className='landing-side-img left'>
                  <GatsbyImage fluid={data.landingBody1.childImageSharp.fluid} />
                </div>
                <div className='landing-phone'>
                  <GatsbyImage fluid={data.phone.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='padding-section'>
          <div className='grid-container row-reverse'>
            <div className='grid-item-2'>
              <div className='landing-side-img right'>
                <GatsbyImage fluid={data.landingBody2.childImageSharp.fluid} />
              </div>
            </div>
            <div className='grid-item-2'>
              <div className='landing-body-section right'>
                <div>
                  <h1>NUTRITIOUS FOODS FROM THE FRESHEST HARVESTS</h1>
                  <div className='landing-hidden-img'>
                    <GatsbyImage fluid={data.landingBody2.childImageSharp.fluid} />
                  </div>
                  <p className='landing-p'>Your food is harvested at its peak directly from local Alabama farms. Everything comes straight from the farm soil just days before your delivery in order to maximize freshness, flavor and nutrition.</p>
                  <Link to='/sources/' className='btn primary-btn landing-btn'>SEE FEATURED FARMS</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='green-section'>
          <div className='stripes'><span className='stripe' /><span className='stripe' /></div>
          <div className='grid-container position-relative'>
            <div className='grid-item-2'>
              <div className='landing-body-section left'>
                <div>
                  <h1>SAVE TIME WHEN YOU SHOP LOCAL ONLINE</h1>
                  <div className='landing-hidden-img'>
                    <GatsbyImage fluid={data.landingBody3.childImageSharp.fluid} />
                  </div>
                  <p className='landing-p'>Using till means no more hassling with parking, crowds, heat and limited selection. Simply place your custom-made order for local, farm-fresh goods and conveniently have them delivered to your doorstep.</p>
                  <Link to='/how-till-works/'className='btn white-btn landing-btn'>LEARN HOW TILL WORKS</Link>
                </div>
              </div>
            </div>
            <div className='grid-item-2'>
              <div className='position-relative'>
                <div className='landing-body-img'>
                  <GatsbyImage fluid={data.landingBody3.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='padding-section'>
          <div className='layout-container'>
            <div className='width-container text-center'>
              <h1>ALABAMA THRIVES WHEN YOU USE TILL</h1>
              <p className='landing-p'>Discover some of the ways the Birmingham community benefits when you use our products and services</p>
            </div>
            <div className='grid-container add-width-2'>
              {landingGridItems.map( ({ img, heading, description }, index) => {
                return (
                  <div className='grid-item-3 landing-grid-item' key={`landing-grid-${index}`}>
                    <div className='img-container'>
                      <GatsbyImage fluid={data[img].childImageSharp.fluid} style={{width: '100%'}} />
                      <div className='content'>
                        <h3>{heading}</h3>
                        <p className='landing-p'>{description}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='text-center big-margin add-padding-top'>
              <Link to='/about/' className='btn primary-btn landing-btn'>LEARN MORE ABOUT US</Link>
              <h3 className='big-margin text-muted'>PROUD TO BE PRAISED IN...</h3>
              <div className='feature-logos width-container'>
                {featureLogos.map( logo => (
                  <div key={logo} className='img'>
                    <GatsbyImage fixed={data[logo].childImageSharp.fixed} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className='teal-section'>
          <div className='grid-container position-relative'>
            <div className='grid-item-2'>
              <div className='position-relative'>
                <div className='landing-body-img'>
                  <GatsbyImage fluid={data.landingBottom.childImageSharp.fluid} />
                </div>
              </div>
            </div>
            <div className='grid-item-2'>
              <div className='landing-body-section right'>
                <div>
                  <h1>SOURCING LOCAL FOOD HAS NEVER BEEN EASIER</h1>
                  <div className='landing-hidden-img'>
                    <GatsbyImage fluid={data.landingBottom.childImageSharp.fluid} />
                  </div>
                  <p className='landing-p'>The most delicious and nutritious locally purveyed protein, produce and pantry items are just a few clicks away...so what are you waiting for? Get started today!</p>
                  <Link to='/signup/' className='btn white-btn landing-btn'>CREATE AN ACCOUNT TODAY</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
