import React from 'react';
import { Link } from 'gatsby';
import Dialog from './Dialog';
import ZipForm from './ZipForm';
import Icon from './Icon';
import { VALID_ZIP_CODES, FOOD_ROUTE, FARM_ROUTE } from '../constants';

const LandingSearchForm = () => {
    const [zipCode, setZipCode] = React.useState("");
    const [validZip, setValidZip] = React.useState(true);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    function handleClose() {
        setDialogOpen(false);
    }
    function submitAction() {
        setValidZip(VALID_ZIP_CODES.includes(zipCode));
        setDialogOpen(true);
    }
    return (
        <div className='landing-zip'>
            <ZipForm 
                submitAction={submitAction}
                btnText="SHOP NOW"
                btnFlexBasis={130}
                zipCode={zipCode}
                setZipCode={setZipCode}
                placeholder='Enter your ZIP code'
            />
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth={500}
            >
                <div className='text-center result-dialog-container'>
                    <button className='btn close-btn' onClick={handleClose}>
                        <Icon iconName='times' width={18} />
                    </button>
                    <div className={`icon-container${validZip ? "" : " error"}`}>
                        <Icon iconName={validZip ? "mapMarkerCheck" : "mapMarkerTimes"} width={60} />
                    </div>
                    <h5>
                        {validZip ? 
                        "Awesome! We deliver locally-sourced goodness in your area. Check out some of our delicious farm-fresh options."
                        :
                        "Sorry, we don't deliver in your neck of the woods yet. But you can still take a look at what we offer!"
                        }
                    </h5>
                    <div className='btn-container grid-container add-width-1'>
                        <div className='grid-item-2 add-padding'>
                            <Link to={FARM_ROUTE} className='btn white-btn'>SEARCH FOR FARMS</Link>
                        </div>
                        <div className='grid-item-2 add-padding'>
                            <Link to={FOOD_ROUTE} className='btn primary-btn'>SEARCH FOR FOOD</Link>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default LandingSearchForm